import React from 'react'
import Helmet from 'react-helmet'
import LogRocket from 'logrocket';
import useSiteMetadata from '@blocks-helpers/useSiteMetadata'
import favicon from '../../assets/favicon.png';

const Seo = ({
               title,
               description,
               excerpt,
               meta,
               ld_json,
               canonical,
               keywords,
               author,
               thumbnail,
               siteUrl
             }) => {
  const site = useSiteMetadata()

  const social = (author && author.social) || site.social || []
  const twitter =
    social.find(s => s.name && s.name.toLowerCase() === 'twitter') || {}

  description = excerpt || description || site.description

  thumbnail = thumbnail && thumbnail.hero && thumbnail.hero.src
  const thumbnailUrl =
    thumbnail &&
    (thumbnail.startsWith('//')
      ? thumbnail
      : siteUrl && `${siteUrl}${thumbnail}`)

  /**
   * Meta Tags
   */

  const metaTags = [
    { itemprop: 'name', content: title || site.title },
    { itemprop: 'description', content: description },
    { name: 'description', content: description },
    { name: 'theme-color', content: '#e44745' },

    { property: 'og:title', content: title || site.title },
    { property: 'og:url', content: `https://bisttros.com/${canonical}` },
    { property: 'og:description', content: description },
    { property: 'og:type', content: 'website' },
    { property: 'og:site_name', content: site.name },
    { property: 'og:image', content: thumbnailUrl },

    { name: 'twitter:card', content: 'summary' },
    { name: 'twitter:site', content: site.name },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:creator', content: 'https://twitter.com/bisttros' }
  ]

  if (keywords && keywords.length > 0) {
    metaTags.push({ name: 'keywords', content: keywords.join(', ') })
  }

  if (meta) {
    metaTags.push(meta)
  }

  metaTags.push({ name: 'initial-scale', content: '1.0' })
  LogRocket.init('bisttros-site/bisttros-site');
  return (
    <Helmet
      htmlAttributes={{
        lang: 'es'
      }}
      title={title}
      titleTemplate={`%s | ${site.title}`}
      meta={metaTags}
    >
      <link rel='canonical' href={`https://bisttros.com/${canonical}`} />
      <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      <link rel="apple-touch-icon" href={favicon}/>
      <link rel="apple-touch-icon-precomposed" href={favicon}/>
      <script type='application/ld+json'>
        {JSON.stringify(ld_json)}
      </script>
      <script defer src='https://static.cloudflareinsights.com/beacon.min.js' data-cf-beacon='{"token": "87d8248a480d4d4785cde367ef78df41"}'></script>
    </Helmet>
  )
}

export default Seo
