import React from 'react';
import { Text, Link } from 'theme-ui'

function FormLegal() {
  return <Text
    sx={{fontSize: 2, textAlign: 'center'}}>
    Al registrarte estás aceptando nuestros <a href='https://docs.google.com/document/u/1/d/e/2PACX-1vRLgN5nBooN4UDRdT5OsomM9wN192G5UyHTraN11RdqvOBdKonBKY_hkhlAG7y3fDJIF-BnZwNd8qZr/pub' target='_blank' style={{textDecoration: 'none', color:'#e44745'}}>términos y condiciones</a>
  </Text>;
}

export default FormLegal;